.application-main {
  width: 80%;
  margin: 0 auto;
}

body {
  padding: 1rem;
}

.nes-container.with-title {
  margin-bottom: 2em;
}

.message-answered {
  opacity: 0.5;
}
